<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { vue } from '@/main'
import { TYPES as LEAD_CONTACT_TYPES } from '../../../organization/contact/contact/lead-contact/store'

export default {
  name: 'controlBodiesMixin',
  props: ['controlBodies'],
  created () {
    if (!this.controlBodyList || (this.controlBodyList && this.controlBodyList.length === 0)) {
      this.setControlbodiesList({})
    }
  },
  data () {
    return {
      form: {}
    }
  },
  computed: {
    ...mapGetters({
      controlBodyList: LEAD_CONTACT_TYPES.GENERIC.organization.customercontact.LIST.GETTERS.list
    }),
    controlBodyListOptions () {
      const options = []
      if (this.controlBodyList && this.controlBodyList.results && this.controlBodyList.results.length > 0) {
        this.controlBodyList.results.forEach((controlbody) => {
          const option = {
            id: controlbody.id,
            first_name: controlbody.first_name,
            last_name: controlbody.last_name,
            name: `${controlbody.first_name} ${controlbody.last_name}`
          }
          if (this.controlBodies && this.controlBodies.length > 0) {
            if (!this.controlBodies.some((instance) => { return option.id === instance.controlBodies })) {
              options.push(option)
            }
          } else {
            options.push(option)
          }
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setControlBodiesList: LEAD_CONTACT_TYPES.GENERIC.organization.customercontact.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getControlBodiesList: LEAD_CONTACT_TYPES.GENERIC.organization.customercontact.LIST.ACTIONS.setFilters
    }),
    onSearchControlBody (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.getControlBodiesList({ filter__last_name__icontains: search, filter__organizations__organization_types__code: 'L' }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setControlBodiesList({})
      }
    },
    selectControlBody (item) {
      this.setControlBodiesList({})
      const newInstance = {
        contact: item.id,
        contact_first_name: item.first_name,
        contact_last_name: item.last_name
      }
      if (this.id && this.form.contact && this.form.contact_first_name) {
        this.$emit('show-delete-modal', { instance: this.form, newInstance: newInstance })
        return
      }
      this.$set(this.form, 'contact', newInstance.contact)
      this.$set(this.form, 'contact_first_name', newInstance.contact_first_name)
      this.$set(this.form, 'contact_last_name', newInstance.contact_last_name)
    },
    clearControlbody () {
      this.setControlBodiesList({})
      if (this.id) {
        this.$emit('show-delete-modal', { instance: this.form })
        return
      }
      this.$set(this.form, 'contact', null)
      this.$set(this.form, 'contact_first_name', null)
      this.$set(this.form, 'contact_last_name', null)
    }
  }
}
</script>
