<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      @check-all="checkAll"
      @check-none="checkNone"
      @set-selected-row="setSelectedRow"
      :show-checks="showChecks"
      :showEditButton="showEditButton"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-edit-form="$emit('open-edit-form', $event)"
    >
      <template slot="other-buttons-actions-bottom" slot-scope="row">
        <b-btn
          variant="outline-primary"
          v-has-perms="deleteRolePerm"
          :title="'Remove controller' | translate"
          @click="showConfirmModal(row.item)"
        >
          <i class="fas fa-times"></i>
        </b-btn>
      </template>
    </thux-table>
    <ConfirmDialog
      v-if="showDeleteModal && controlBodyToDelete.id"
      ref-id="modal-delete"
      :title="$t('Are you sure to remove this controller?')"
      :button-confirm-text="'Remove' | translate"
      @confirm-dialog-close="closeConfirmModal"
      @confirm-dialog-select="deleteControlBody"
    >
        <template slot="body">
          <b-table
            no-local-sorting
            show-empty
            striped
            hover
            responsive
            :items="[controlBodyToDelete]"
            :fields="controlBodyFields"
          >
          </b-table>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as YARD_CONTROL_BODY_TYPES } from '../../yard-control-body/store'

import ThuxTableMixin from '../../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import ConfirmDialog from '../../../../components/ConfirmDialog'

export default {
  name: 'YardControlBodiesTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  components: { ConfirmDialog },
  data () {
    return {
      controlBodyFields: [
        {
          key: 'contact_first_name',
          label: this.$t('First name'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          key: 'contact_last_name',
          label: this.$t('Last name'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        }
      ],
      showDeleteModal: false,
      controlBodyToDelete: {},
      fields: [
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'contact__first_name',
          label: this.$t('First name'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'contact_first_name'
        },
        {
          key: 'contact__last_name',
          label: this.$t('Last name'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'contact_last_name'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'contact__first_name', 'contact__last_name'],
      // Permissions
      deleteRolePerm: 'yard_yardcontrolbody_destroy'
    }
  },
  computed: {
    ...mapGetters({
      selectAll: YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.LIST.GETTERS.selectAll,
      selectedList: YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return false
    },
    showDetailButton () {
      return false
    },
    showEditButton () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setList: YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.LIST.MUTATIONS.setList,
      setSelectedList: YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.LIST.MUTATIONS.setSelectedList,
      setSelectAll: YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.LIST.ACTIONS,
      delete: YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.DETAIL.ACTIONS.delete
    }),
    showConfirmModal (instance) {
      this.$set(this, 'controlBodyToDelete', instance)
      this.$set(this, 'showDeleteModal', true)
    },
    closeConfirmModal () {
      this.$set(this, 'controlBodyToDelete', {})
      this.$set(this, 'showDeleteModal', false)
    },
    deleteControlBody () {
      const promise = this.delete(this.controlBodyToDelete)
      promise.then(
        () => {
          setTimeout(() => {
            this.$emit('refresh-list')
            this.closeConfirmModal()
          }, 500)
        }
      )
    }
  }
}
</script>
