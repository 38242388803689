<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <b-form>
      <b-form-row>
        <form-thux-horizontal-autocomplete
          :validator="$v.form.contact"
          :label-form="'Control body' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          :disabled="false"
          :label-search="'name'"
          labelColsSm="3"
          labelColsLg="3"
          :value="form.contact ? { id: form.contact, name: `${form.contact_first_name} ${form.contact_last_name}` } : null"
          :options="controlBodyListOptions"
          :placeholder="$t('Search by last name')"
          :placeholder-field="form.contact_last_name"
          @search="onSearchControlBody"
          @select="selectControlBody"
          @clear="clearControlbody"
        />
      </b-form-row>
    </b-form>
    <div class="mt-3 f-w-600 d-flex justify-content-end">
      <b-button
        class="mr-3"
        variant="default"
        @click.prevent="formEditable ? cancel() : closeForm()">
        {{ formEditable ? 'Cancel' : 'Close' | translate }}
      </b-button>
      <b-button
        variant="primary"
        v-has-perms="editRolePerm"
        @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
        :disabled="$v.form.$invalid">
        {{ formEditable ? 'Save' : 'Edit' | translate }}
      </b-button>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as YARD_CONTROL_BODY_TYPES } from '../../yard-control-body/store'

import ThuxDetailPanelMixin from '../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import ControlBodyMixin from '../mixins/ControlBodyMixin'

export default {
  name: 'YardControlBodyEdit',
  props: {
    yard: { required: true }
  },
  mixins: [ThuxDetailPanelMixin, ControlBodyMixin],
  data () {
    return {
      pageName: 'Control body',
      form: {},
      rolePerm: 'yard_yardcontrolbody_retrieve',
      editRolePerm: 'yard_yardcontrolbody_update'
    }
  },
  computed: {
    ...mapGetters({
      ...YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.DETAIL.GETTERS
    })
  },
  methods: {
    ...mapActions({
      ...YARD_CONTROL_BODY_TYPES.GENERIC.yard.yardcontrolbody.DETAIL.ACTIONS
    }),
    setForm () {
      const form = Object.assign({}, this.detail)
      this.$set(this, 'form', form)
    },
    getFormData () {
      const formData = Object.assign({}, this.form)
      formData.yard = this.yard
      return formData
    },
    selectControlBody (item) {
      this.setControlBodiesList({})
      this.$set(this.form, 'contact', item.id)
      this.$set(this.form, 'contact_first_name', item.first_name)
      this.$set(this.form, 'contact_last_name', item.last_name)
    },
    clearControlBody () {
      this.setControlBodiesList({})
      this.$set(this.form, 'contact', null)
      this.$set(this.form, 'contact_first_name', null)
      this.$set(this.form, 'contact_last_name', null)
    }
  },
  validations: {
    form: {
      contact: { required }
    }
  }
}
</script>
